<template>
    <div class="step-5">
        <div class="header">
            <van-icon name="arrow-left" class="header-icon header-icon-left" />
            入群欢迎语
            <van-icon name="ellipsis" class="header-icon header-icon-right" />
        </div>
        <div class="step-4-content"></div>
        <div class="add">
            <van-icon name="plus" />
            <span>添加</span>
        </div>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(6)">知道啦</div>
            <div class="focus1">点击选中我的欢迎语</div>
            <div class="overlay-focus">
                <div class="welcome-words-content">
                    <div class="title">呼朋唤友来参与-群裂变</div>
                    <div class="text">
                        你好～快来参与本次福利活动～分享下方海报，邀请好友扫码助力：「X」位好友扫码助力，即可领取奖品！奖品仅限XX份，当前剩余XX份
                        快去分...
                    </div>
                    <div class="link-box">
                        <img src="../images/poster-img.png" class="poster-img" />
                        <div>
                            <div>点击这里，完成任务领取奖品吧</div>
                            <div class="content">快来参加活动吧</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step2',
    components: {},
    data() {
        return {
            active: 2,
            show: true,
            stepIndex:1
        };
    },
    methods: {
        nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.welcome-words-content {
    border-radius: 8px;
    background: #ffffff;
    padding: 12px 16px;
    .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .text {
        color: #666666;
        margin-bottom: 12px;
    }
    .link-box {
        border-radius: 8px;
        background: #f5f5f5;
        padding: 8px;
        display: flex;
        img {
            margin-right: 8px;
        }
        .content {
            font-size: 12px;
            color: #999999;
            margin-top: 8px;
        }
    }
}
.step-4-content {
    padding: 12px;
}
.add {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 53px;
    line-height: 53px;
    color: #1472ff;
    font-size: 500;
    background: #ffffff;
    text-align: center;
    span {
        margin-left: 5px;
    }
}
.poster-img {
    width: 60px;
}
.overlay-focus {
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
    top: 63px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    margin: 0 12px;
    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .text {
            flex: 1;
            margin-left: 12px;
        }
        .icon {
            color: #999999;
        }
    }
    .tip {
        margin: 8px 0 0 38px;
        color: #999999;
    }
}
.focus1 {
    width: 50%;
    height: 117px;
    background: url('../images/focus5.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 18px;
    position: absolute;
    top: 311px;
    left: 21%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 35%;
    top: 462px;
}
</style>