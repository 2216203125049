<template>
    <div class="step-3">
        <div class="header">
            <van-icon name="arrow-left" class="header-icon header-icon-left" />
            客户群
            <van-icon name="ellipsis" class="header-icon header-icon-right" />
        </div>
        <div class="step3-content">群聊人数上限: 500</div>
        <div class="custome-box custome-box-first">
            <van-icon name="friends" class="green" />
            <div>创建一个客户群</div>
        </div>
        <div class="custome-box">
            <div class="title">我的客户群</div>
            <div class="num">
                <span>0</span>
                个
            </div>
        </div>
        <div class="custome-box">
            <div class="title">工具</div>
            <div class="tool">
                <div class="tool-item">
                    <img src="../images/app-icon1.png" />
                    <div>加入群聊</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon2.png" />
                    <div>入群欢迎语</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon3.png" />
                    <div>快捷回复</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon4.png" />
                    <div>聊天工具栏</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon5.png" />
                    <div>防骚扰</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon6.png" />
                    <div>群成员去重</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon7.png" />
                    <div>群模板</div>
                </div>
                <div class="tool-item">
                    <img src="../images/app-icon8.png" />
                    <div>群聊数据统计</div>
                </div>
            </div>
        </div>
        <div class="custome-box">
            <div class="title">同事动态</div>
            <div class="colleague-action">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(4)">知道啦</div>
            <div class="focus1">
                点击【入群欢迎语】
                <br />
                进入入群欢迎语素材库
            </div>
            <div class="overlay-focus">
                <img src="../images/app-icon2.png" />
                <div>入群欢迎语</div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step3',
    components: {},
    data() {
        return {
            show: true,
            stepIndex: 1
        };
    },
    methods: {
        nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.step3-content {
    padding: 16px 26px 45px 26px;
    background: #4873c0;
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    margin-top: -2px;
}
.custome-box {
    font-size: 15px;
    background: #ffffff;
    border-radius: 6px;
    margin: 0 12px 8px 12px;
    padding: 16px;
    .title {
        font-weight: 500;
    }
    .num {
        text-align: center;
        padding: 10px 0 5px 0;
        font-weight: 500;
        span {
            font-size: 30px;
        }
    }
    .green {
        color: #07c160;
        font-size: 18px;
        margin-right: 10px;
    }
    .tool {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 12px;
        margin: 12px -16px -16px -16px;
        .tool-item {
            display: flex;
            align-items: center;
            padding: 12px 0 12px 24px;
            border-top: 1px solid #f5f3f3;
            &:nth-child(odd) {
                border-right: 1px solid #f5f3f3;
            }
            img {
                margin-right: 12px;
                width: 24px;
            }
        }
    }
    .colleague-action {
        display: flex;
        margin-top: 10px;
        div {
            height: 100px;
            flex-basis: 33%;
            margin-right: 10px;
            background: #eeeeee;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.custome-box-first {
    margin-top: -27px;
    display: flex;
    align-items: center;
}
.overlay-focus {
    position: absolute;
    width: 47%;
    padding: 12px 0 12px 24px;
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    top: 325px;
    right: 3%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 16px;
    img {
        width: 24px;
        margin-right: 10px;
    }
    .icon {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        margin-right: 16px;
        background: #07c160;
        border-radius: 4px;
    }
}
.focus1 {
    width: 52%;
    height: 145px;
    background: url('../images/focus3.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 28px;
    position: absolute;
    top: 370px;
    left: 30%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 19%;
    top: 540px;
}
</style>