<template>
    <div class="step-2">
        <div class="header">
            工作台
            <van-icon name="wap-nav" class="header-icon header-icon-right" />
        </div>
        <div class="step2-content">
            <div class="job-item">
                <div class="title">客户管理与经营</div>
                <div class="item">
                    <div class="icon">
                        <van-icon name="chat" />
                    </div>
                    <div class="text">客户联系</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <van-icon name="friends" />
                    </div>
                    <div class="text">客户群</div>
                </div>
            </div>
            <div class="job-item">
                <div class="title">内部分享</div>
                <div class="item">
                    <div class="icon icon-example"></div>
                    <div class="text text-example"></div>
                </div>
                <div class="item">
                    <div class="icon icon-example"></div>
                    <div class="text text-example"></div>
                </div>
            </div>
            <div class="job-item">
                <div class="title">效率办公</div>
                <div class="item">
                    <div class="icon icon-example"></div>
                    <div class="text text-example"></div>
                </div>
                <div class="item">
                    <div class="icon icon-example"></div>
                    <div class="text text-example"></div>
                </div>
            </div>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="chat-o">消息</van-tabbar-item>
            <van-tabbar-item icon="cluster-o">通讯录</van-tabbar-item>
            <van-tabbar-item icon="apps-o">工作台</van-tabbar-item>
            <van-tabbar-item icon="manager-o">我</van-tabbar-item>
        </van-tabbar>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(3)">知道啦</div>
            <div class="focus1">点击【客户群】——进入客户群</div>
            <div class="overlay-focus">
                <div class="icon">
                    <van-icon name="friends" />
                </div>
                <div class="text">客户群</div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step2',
    components: {},
    data() {
        return {
            active: 2,
            show: true,
            stepIndex:1
        };
    },
    methods: {
       nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.step2-content {
    background: rgba(255, 255, 255, 0);
    box-shadow: inset 0 -0.5px 0 0 rgba(235, 235, 235, 1);
    height: calc(100vh - 55px - 50px);
    .job-item {
        background: #ffffff;
        padding-left: 16px;
        margin-bottom: 8px;
        .title {
            font-size: 13px;
            font-weight: 500;
            height: 38px;
            line-height: 38px;
            border-bottom: 1px solid #ebebeb;
            color: #666666;
        }
        .item {
            display: flex;
            align-items: center;
            height: 55px;
            line-height: 55px;
            .icon {
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #fff;
                margin-right: 16px;
                background: #07c160;
                border-radius: 4px;
            }
            .icon-example {
                background: #eeeeee;
            }
            .text {
                color: rgba(0, 0, 0, 1);
                font-size: 16px;
                border-bottom: 1px solid #ebebeb;
                height: 100%;
                flex: 1;
            }
            .text-example {
                width: 64px;
                flex: unset;
                height: 24px;
                border-radius: 2px;
                opacity: 1;
                background: rgba(238, 238, 238, 1);
            }
        }
        .item:last-child {
            .text {
                border-bottom: none;
            }
        }
    }
}
.overlay-focus {
    position: absolute;
    width: 100%;
    height: 55px;
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    top: 146px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 16px;
    .icon {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        margin-right: 16px;
        background: #07c160;
        border-radius: 4px;
    }
}
.focus1 {
    width: 72%;
    height: 117px;
    background: url('../images/focus2.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 20px;
    position: absolute;
    top: 201px;
    left: 13%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 23%;
    top: 352px;
}
</style>