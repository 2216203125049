<template>
    <div class="step-5">
        <div class="header">
            <van-icon name="arrow-left" class="header-icon header-icon-left" />
            入群欢迎语
            <van-icon name="ellipsis" class="header-icon header-icon-right" />
        </div>
        <div class="step-4-content">
            <div class="welcome-words-content">
                <div class="title">呼朋唤友来参与-群裂变</div>
                <div class="text">
                    你好～快来参与本次福利活动～分享下方海报，邀请好友扫码助力：「X」位好友扫码助力，即可领取奖品！奖品仅限XX份，当前剩余XX份
                    快去分...
                </div>
                <div class="link-box">
                    <img src="../images/poster-img.png" class="poster-img" />
                    <div>
                        <div>点击这里，完成任务领取奖品吧</div>
                        <div class="content">快来参加活动吧</div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(7)">知道啦</div>
            <div class="focus1">
                点击【选择客户群】
                <br />
                进入我的客户群
            </div>
            <div class="overlay-focus">
                <div class="group-list">该入群欢迎语生效的客户群：</div>
                <div class="welcome-words-content">
                    <div class="select-group">
                        <div class="txt">选择客户群</div>
                        <van-icon name="arrow" />
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step6',
    components: {},
    data() {
        return {
            active: 2,
            show: true,
            stepIndex: 1
        };
    },
    methods: {
        nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.welcome-words-content {
    border-radius: 8px;
    background: #ffffff;
    padding: 12px 16px;
    .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .text {
        color: #666666;
        margin-bottom: 12px;
    }
    .link-box {
        border-radius: 8px;
        background: #f5f5f5;
        padding: 8px;
        display: flex;
        img {
            margin-right: 8px;
        }
        .content {
            font-size: 12px;
            color: #999999;
            margin-top: 8px;
        }
    }
    .select-group {
        display: flex;
        align-items: center;
        .txt {
            flex: 1;
        }
        .van-icon {
            color: #999999;
        }
    }
}
.group-list {
    font-size: 12px;
    color: #999999;
    margin: 12px 0;
}
.step-4-content {
    padding: 12px;
}
.add {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 53px;
    line-height: 53px;
    color: #1472ff;
    font-size: 500;
    background: #ffffff;
    text-align: center;
    span {
        margin-left: 5px;
    }
}
.poster-img {
    width: 60px;
}
.overlay-focus {
    position: relative;
    background: #f5f6f7;
    top: 265px;
    box-sizing: border-box;
    padding: 12px 16px;
    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .text {
            flex: 1;
            margin-left: 12px;
        }
        .icon {
            color: #999999;
        }
    }
    .tip {
        margin: 8px 0 0 38px;
        color: #999999;
    }
}
.focus1 {
    width: 46%;
    height: 145px;
    background: url('../images/focus6.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 28px;
    position: absolute;
    top: 372px;
    left: 25%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 30%;
    top: 549px;
}
</style>