<template>
    <div class="step-4">
        <div class="header">
            <van-icon name="arrow-left" class="header-icon header-icon-left" />
            入群欢迎语
            <van-icon name="ellipsis" class="header-icon header-icon-right" />
        </div>
        <div class="step-4-content">
            <div class="welcome-words">
                <div class="title">
                    <img src="../images/app-icon2.png" class="icon icon-img" />
                    <div class="text">设置成员的入群欢迎语</div>
                    <van-icon name="arrow" class="icon" />
                </div>
                <div class="tip">管理员或负责人配置后，成员可以选择并配置到自己的客户群中</div>
            </div>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="chat-o">消息</van-tabbar-item>
            <van-tabbar-item icon="cluster-o">通讯录</van-tabbar-item>
            <van-tabbar-item icon="apps-o">工作台</van-tabbar-item>
            <van-tabbar-item icon="manager-o">我</van-tabbar-item>
        </van-tabbar>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(5)">知道啦</div>
            <div class="focus1">
                点击【我的入群欢迎语】
                <br />
                进入我的入群欢迎语素材库
            </div>
            <div class="overlay-focus">
                <div class="title">
                    <img src="../images/welcome-icon.png" class="icon icon-img" />
                    <div class="text">我的入群欢迎语</div>
                    <van-icon name="arrow" class="icon" />
                </div>
                <div class="tip">在这里管理自己的入群欢迎语，并可批量配置到客户群中</div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step2',
    components: {},
    data() {
        return {
            active: 2,
            show: true,
            stepIndex: 1
        };
    },
    methods: {
        nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.icon-img {
    width: 24px;
}
.step-4-content {
    padding: 12px;
    .welcome-words {
        border-radius: 8px;
        background: #ffffff;
        padding: 12px 16px;
        margin-bottom: 12px;
        .title {
            display: flex;
            align-items: center;
            font-size: 16px;
            .text {
                flex: 1;
                margin-left: 12px;
            }
            .icon {
                color: #999999;
            }
        }
        .tip {
            margin: 8px 0 0 38px;
            color: #999999;
        }
    }
}
.overlay-focus {
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
    top: 167px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    margin: 0 12px;
    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .text {
            flex: 1;
            margin-left: 12px;
        }
        .icon {
            color: #999999;
        }
    }
    .tip {
        margin: 8px 0 0 38px;
        color: #999999;
    }
}
.focus1 {
    width: 60%;
    height: 145px;
    background: url('../images/focus4.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 28px;
    position: absolute;
    top: 259px;
    left: 21%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 20%;
    top: 437px;
}
</style>