<template>
    <div class="guide-page">
        <component :is="currentView" @nextStepPage="nextStepPage" />
    </div>
</template>

<script>
import step1 from './guide-step/step-1.vue';
import step2 from './guide-step/step-2.vue';
import step3 from './guide-step/step-3.vue';
import step4 from './guide-step/step-4.vue';
import step5 from './guide-step/step-5.vue';
import step6 from './guide-step/step-6.vue';
import step7 from './guide-step/step-7.vue';

export default {
    name: 'guideGroup',
    components: {
        step1,
        step2,
        step3,
        step4,
        step5,
        step6,
        step7
    },
    data() {
        return {
            stepIndex: 1
        };
    },
    methods: {
        nextStepPage(num) {
            this.stepIndex = num;
        }
    },
    computed: {
        currentView() {
            switch (this.stepIndex) {
                case 1:
                    return 'step1';
                case 2:
                    return 'step2';
                case 3:
                    return 'step3';
                case 4:
                    return 'step4';
                case 5:
                    return 'step5';
                case 6:
                    return 'step6';
                case 7:
                    return 'step7';
            }

            return 'step1';
        }
    }
};
</script>