<template>
    <div class="step-5">
        <div class="header">
            <van-icon name="arrow-left" class="header-icon header-icon-left" />
            入群欢迎语
            <van-icon name="ellipsis" class="header-icon header-icon-right" />
        </div>
        <div class="select-box flex-box">
            <div>按群名筛选</div>
            <van-icon name="arrow-down" />
        </div>
        <div class="check-box flex-box">
            <div class="check-icon"><van-icon name="success" /></div>
            <div class="text">全部可选客户群</div>
            <van-icon name="info-o" />
        </div>
        <div class="date-item">10月27日</div>
        <div class="white-box"></div>
        <div class="footer-select flex-box">
            <img src="../images/group-img.png" class="group-pic" />
            <div>确定(1)</div>
        </div>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep">知道啦</div>
            <div class="focus1">选中客户群后——点击【确认】</div>
            <div class="overlay-focus">
                <div class="check-box flex-box">
                    <div class="check-icon"><van-icon name="success" /></div>
                    <div class="flex-box flex-content-box">
                        <img src="../images/group-img.png" class="group-pic" />
                        <div class="group-name">
                            <div>群名称</div>
                            <div>3位成员</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step2',
    components: {},
    data() {
        return {
            active: 2,
            show: true,
            stepIndex: 1
        };
    },
    methods: {
        nextStep() {
            this.$router.go(-1);
            this.$emit('nextStepPage', 1);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.flex-box {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 48px;
    padding: 0 12px;
    background: #ffffff;
}
.flex-content-box {
    flex: 1;
    padding: 0;
    margin-right: -12px;
}
.select-box {
    font-size: 13px;
    color: #999999;
    border-bottom: 1px solid #ebebeb;
    div {
        margin-right: 5px;
    }
}
.group-pic {
    width: 34px;
    margin-right: 12px;
}
.check-box {
    .check-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: 13px;
        font-size: 17px;
        color: #ffffff;
        background: #1472ff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text {
        margin-right: 4px;
    }
    .group-name {
        line-height: 1.2;
        font-size: 12px;
        color: #999999;
        flex: 1;
        div {
            &:first-child {
                font-size: 14px;
                color: #333333;
            }
        }
    }
}
.date-item {
    padding: 0px 12px;
    font-size: 12px;
    height: 27px;
    line-height: 27px;
    color: #666666;
}
.white-box {
    background: #ffffff;
    height: calc(100vh - 274px);
}
.footer-select {
    background: none;
    justify-content: space-between;
    div {
        width: 77px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #1472ff;
        color: #ffffff;
        border-radius: 4px;
    }
}
.overlay-focus {
    position: relative;
    background: #ffffff;
    top: 174px;
    box-sizing: border-box;
    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .text {
            flex: 1;
            margin-left: 12px;
        }
        .icon {
            color: #999999;
        }
    }
    .tip {
        margin: 8px 0 0 38px;
        color: #999999;
    }
}
.focus1 {
    width: 69%;
    height: 117px;
    background: url('../images/focus7.png') no-repeat;
    background-size: 100% 100%;
    color: #1472ff;
    font-size: 16px;
    font-weight: 600;
    padding: 65px 0 0 18px;
    position: absolute;
    top: 222px;
    left: 11%;
    box-sizing: border-box;
}
.next-btn {
    position: absolute;
    right: 26%;
    top: 383px;
}
</style>