<template>
    <div class="step-1">
        <div class="header">
            <van-icon name="wap-nav" class="header-icon header-icon-left" />
            <div>消息(99+)</div>
            <van-icon name="add-o" class="header-icon header-icon-right" />
        </div>
        <div class="content">
            <div class="search-bar">
                <van-icon name="search" />
                <div>搜索</div>
            </div>
            <div class="message-item">
                <div class="head"></div>
                <div class="message-content">
                    <div class="title"></div>
                    <div class="describe"></div>
                </div>
            </div>
            <div class="message-item">
                <div class="head"></div>
                <div class="message-content">
                    <div class="title"></div>
                    <div class="describe"></div>
                </div>
            </div>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="chat-o">消息</van-tabbar-item>
            <van-tabbar-item icon="cluster-o">通讯录</van-tabbar-item>
            <van-tabbar-item icon="apps-o">工作台</van-tabbar-item>
            <van-tabbar-item icon="manager-o">我</van-tabbar-item>
        </van-tabbar>
        <van-overlay :show="show">
            <div class="next-btn" @click="nextStep(2)">知道啦</div>
            <div class="focus1">点击【工作台】——进入我的工作台</div>
            <div class="overlay-focus">
                <van-icon name="apps-o" class="overlay-focus-icon" />
                <div>工作台</div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'step1',
    components: {},
    data() {
        return {
            active: 0,
            show: true,
            stepIndex: 1
        };
    },
    methods: {
        nextStep(n) {
            this.stepIndex = n;
            this.$emit('nextStepPage', this.stepIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
.step-1 {
    .content {
        background: rgba(255, 255, 255, 0);
        box-shadow: inset 0 -0.5px 0 0 rgba(235, 235, 235, 1);
        padding: 8px;
        height: calc(100vh - 55px - 50px);
        .search-bar {
            height: 32px;
            line-height: 32px;
            border-radius: 6px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(178, 178, 178, 1);
            font-size: 14px;
            font-weight: 500;
            .van-icon-search {
                margin-right: 5px;
            }
        }
        .message-item {
            height: 66px;
            border-bottom: 1px solid #ebebeb;
            display: flex;
            align-items: center;
            .head {
                width: 47px;
                height: 47px;
                border-radius: 6px;
                margin-right: 11px;
                background: rgba(238, 238, 238, 1);
            }
            .message-content {
                div {
                    background: rgba(238, 238, 238, 1);
                }
                .title {
                    width: 112px;
                    margin-bottom: 4px;
                    height: 19px;
                }
                .describe {
                    width: 150px;
                    height: 15px;
                }
            }
        }
    }
    .overlay-focus {
        position: absolute;
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-radius: 10px;
        text-align: center;
        bottom: 0;
        right: 28%;
        font-size: 12px;
        padding-top: 10px;
        box-sizing: border-box;
        .overlay-focus-icon {
            font-size: 22px;
        }
    }
    .focus1 {
        width: 80%;
        height: 117px;
        background: url('../images/focus1.png') no-repeat;
        background-size: 100% 100%;
        color: #1472ff;
        font-size: 16px;
        font-weight: 600;
        padding: 29px 0 0 38px;
        position: absolute;
        bottom: 60px;
        left: 12%;
        box-sizing: border-box;
    }
    .next-btn {
        position: absolute;
        right: 10%;
        bottom: 235px;
    }
}
</style>
